@import './theme.scss';

$accordion-button-active-bg: $denim;
$accordion-button-active-color: $snow;

$body-bg: #f6f2ee;
$body-secondary-bg: #eeeae2;
$body-color: #17110c;
$body-color-light: #797d78;

$border-color: #d3ccc3;

$component-active-color: $snow;
$component-active-bg: $farmshare-green;

$font-family-base: 'Figtree';
$font-size-root: 15px;

$headings-font-family: 'Poppins';

$link-color: $denim;
$link-color-dark: $farmshare-yellow;

$nav-link-color-light: $denim;
$nav-link-color-dark: $farmshare-yellow;

$navbar-brand-margin-end: 3rem;
$navbar-light-color: $denim;
$navbar-light-active-color: $farmshare-green;
$navbar-dark-color: $farmshare-yellow;
$navbar-dark-active-color: $farmshare-yellow;

$pagination-color: $denim;
