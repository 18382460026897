.tableEditor {
  display: flex;
  gap: 5px;
  align-items: center;

  &Input {
    width: 82px;
    padding: 2px 8px;
  }
}
