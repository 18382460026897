@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');

// Theme Colors
@import './theme.scss';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import './variables.scss';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '~bootstrap/scss/variables';

// 4. Include any default map overrides here
$custom-colors: (
  'blue': $denim,
  'lime': $lime,
);

$custom-theme-colors: (
  'primary': $farmshare-green,
  'success': $success,
  'danger': $danger,
  'warning': $farmshare-yellow,
  'lime': $lime,
  'dark': #343a3f,
);

// Bootstrap default overrides
$font-weight-bold: 600;

$colors: map-merge($colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$primary-text-emphasis: $denim;
$primary-bg-subtle: $farmshare-green-light;
$primary-bg-subtle-dark: #002612;
$primary-border-subtle: tint-color($farmshare-green-light, 10%);

@import '~bootstrap/scss/variables-dark';

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

.rbt-input {
  background-color: inherit !important;

  &-wrapper {
    align-items: baseline;
  }
}

.rbt-token {
  color: $snow !important;
  background-color: $denim !important;
}

// Override styles for fullcalendar
@import './full-calendar.scss';

// Override mobile nav background image whenever we use it.
.navbar-toggler-icon {
  background-image: none;
}

.marketing-btn {
  font-weight: bold;
  transition: 0.1s;
  color: $farmshare-green;
  padding: 12px 26px;
  font-size: 24px;
  border-radius: 20px;
  margin-bottom: 40px;
  background: $lime;
  border: none;
  &:hover {
    box-shadow: inset 0 100px 100px rgba(0, 0, 0, 0.1);
    background: $lime;
    color: $farmshare-green;
  }
  &:active {
    background: $lime !important;
    box-shadow: inset 0 100px 100px rgba(255, 255, 255, 0.1) !important;
    color: $farmshare-green !important;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  html,
  body {
    // width: 210mm;
    /* height: 297mm; */
    // height: 282mm;
    // font-size: 11px;
    // background: #fff;
    overflow: visible;
  }
}
