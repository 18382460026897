@import 'theme.scss';

.status {
  &Requested {
    background-color: #cc7351;
    border-color: #824d38;
    color: white;
  }

  &Scheduled {
    background-color: #ee4266;
    border-color: #9e2d45;
    color: white;
  }

  &InProgress {
    background-color: #f28800;
    border-color: #a45d01;
    color: white;
  }

  &Completed {
    background-color: $farmshare-green;
    border-color: $farmshare-green;
    color: white;
  }

  &.rounded {
    border-radius: 4px;
  }
}
