.bigCalendar {
  &Day {
    height: 160px;
  }
  &HeaderPanel {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0;
  }
  &Header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0;
  }
}
