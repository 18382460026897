.event {
  &Wrapper {
    display: flex;
    gap: 0.2rem;
    flex-wrap: wrap;
    padding: 0.2rem;
    cursor: pointer;
  }

  &LastName {
    font-weight: bold;
  }
}
