$farmshare-green: #006f35ff;
$farmshare-green-light: #006f3526;
$farmshare-yellow: #ffb659ff;
$light-green: #9ee493ff;
$nyanza: #daf7dcff;
$snow: #fcf7f8ff;
$french-gray: #ced3dcff;
$rose-quartz: #aba9c3ff;
$denim: #275dadff;
$bittersweet: #ff715bff;
$lime: #bced09ff;
$success: #00bf5b;
$danger: #ff3d00;
