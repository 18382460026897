@import '../../../../ui-components/src/styles.scss';
@import 'theme.scss';

.chip {
  background-color: $farmshare-green-light;
  color: $farmshare-green;
  font-weight: 600;
}

[data-bs-theme='dark'] {
  .chip {
    background-color: $farmshare-green;
    color: white;
  }
}

.imgContainer {
  border: 1px solid #d3ccc3 !important;
  border-radius: 100% !important;
  box-shadow: 0px 0px 5px 1px #d3ccc3;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.hr {
  height: 2px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  background-color: #d3ccc3;
  opacity: 1;
  border: none;
}

.headsOfAnimalInput {
  input {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2)) !important;
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2)) !important;
    line-height: 1.25 !important;
  }
}

.header {
  border-bottom: 1px solid $border-color;
}

.scheduleBlock {
  border: 1px solid #d3ccc3;
  border-radius: 10px;
  text-align: center;
}

[data-bs-theme='dark'] {
  .scheduleBlock {
    border: 1px solid white;
    border-radius: 10px;
    text-align: center;
  }
  .unavailableBlock {
    border: 1px solid #121212;
    color: white;
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
}

.unavailableBlock {
  color: $farmshare-green;
  background-color: #006f3533;
  border: 1px solid $farmshare-green;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}

.schedulingDetailsContainer {
  max-width: 600px;
}
