@import '../../../../ui-components/src/styles.scss';

.imgContainer {
  border: 1px solid #d3ccc3;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.descriptionList {
  padding-left: 1rem;
}

.customHR {
  height: 2px;
  margin: 20px 0;
  background-color: #d3ccc3;
  opacity: 1;
  border: none;
}
