.dateDetails {
  --bs-offcanvas-width: 560px;
}

.row {
  &Buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  &Button {
    width: 40px;
  }
}
