.statusColumn {
  width: 200px;
}

.statusCellText {
  padding: 0.375rem 0;
}

.search {
  width: 25%;
  position: relative;
  display: flex;
  align-items: center;

  &Input {
    padding-right: 1rem;
  }

  &Icon {
    position: absolute;
    right: 0.5rem;
  }
}
