@import 'theme.scss';

.bigCalendarHeader {
  &Navigation {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  &Week {
    display: none;
    margin-top: 1rem;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  &Date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding-top: 5px;
    cursor: pointer;

    &Selected {
      border: 1px solid $farmshare-green;
      background-color: $farmshare-green;
      color: white;
      border-radius: 50%;
    }
  }
}
