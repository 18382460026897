@import '../../variables.scss';

.navLink {
  // Need z-index to prevent border radius bug during animation
  z-index: 1;
  padding: 10px 16px !important;
  color: white;
  font-weight: 500;
  margin-right: 12px;
  border-radius: 100px !important;
  &:hover {
    color: white;
    border-radius: 100px !important;
    background-color: rgba($color: #ffffff, $alpha: 0.2);
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
  }
}

.navLink:global(.active) {
  color: white !important;
  background-color: rgba($color: #ffffff, $alpha: 0.2) !important;
}

.landingToggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: none;
}

.landingMobileNavbarIcon {
  background-image: url('../../assets/images/icons/hamburger.svg');
  display: inline-block;
  color: white;
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  // This border gives the illusion of padding to the icon
  border: 5px solid $farmshare-green;
}

.dashboardMobileNavbarIcon {
  background-image: url('../../assets/images/icons/hamburger-green.svg');
  display: inline-block;
  color: $farmshare-green;
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  // This border gives the illusion of padding to the icon
  // border: 5px solid $body-bg;
}
