.calendarDateCell {
  &Link {
    position: absolute;
    bottom: 0;
    left: 0;

    display: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: small;
  }
}
