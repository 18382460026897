.search {
  max-width: 360px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &Input {
    padding-right: 1rem;
  }

  &Icon {
    position: absolute;
    right: 0.5rem;
  }
}
