.legend {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }

  &Item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &Color {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  &Label {
    font-style: italic;
  }
}
