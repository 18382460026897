:root {
  --fc-border-color: var(--bs-border-color);
  --fc-page-bg-color: var(--bs-body-bg);
}

.fc {
  .fc-more-popover {
    visibility: hidden;
  }

  .fc-daygrid-more-link {
    margin-top: 0.5rem;
  }

  .fc-mobileView-view .table-borderless {
    & td,
    & th {
      border-width: 0;
      vertical-align: middle;
      text-align: start;
    }
  }

  .fc-daygrid-day-number {
    position: static;
  }

  .fc-daygrid-day-frame:hover .cell-agenda-link {
    display: block;
  }
}
